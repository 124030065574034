export default class Helpers {
  private static leadingZero(num: number) : string {
    return num > 9 ? num.toString() : `0${num}`;
  }
  
  public static getDateString(date: Date): string {
    const dateString = this.leadingZero(date.getDate());
    const monthString = this.leadingZero(date.getMonth()+1);
    const hoursString = this.leadingZero(date.getHours());
    const minutesString = this.leadingZero(date.getMinutes());
    const secondsString = this.leadingZero(date.getSeconds());

    return `${dateString}.${monthString}.${date.getFullYear()} um ${hoursString}:${minutesString}:${secondsString}`;
  }
}