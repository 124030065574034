import React, { ChangeEvent, Component, createRef, FormEvent, SyntheticEvent } from 'react';
import axios from 'axios';
import {
  Container,
  Dropdown,
  Header,
  Form,
  Label,
  Message,
  DropdownItemProps,
  Checkbox, Button, CheckboxProps, DropdownProps, Input,
} from 'semantic-ui-react';
import ReCAPTCHA from 'react-google-recaptcha';
import Config from '../../../Config';
import { Link } from 'react-router-dom';
// import CoronaInfo from '../CoronaInfo';

interface IBirthdate {
  day?: number | '';
  month?: number | '';
  year?: number | '';
}

interface IRegisterState {
  firstName?: string,
  lastName?: string,
  nickname?: string,
  password?: string,
  passwordRepeat?: string,
  email?: string,
  birthdate?: IBirthdate,
  isLoading?: boolean,
  acceptTerms?: boolean,
  acceptPrivacy?: boolean,
  acceptNewsletter?: boolean,
  registerErrors?: IRegisterErrors,
  registrationSuccess?: boolean,
}

interface IRegisterErrors {
  name: string[],
  username: string[],
  email: string[],
  password: string[],
  confirm_password: string[],
  birthday: string[],
  accept_terms_and_conditions: string[],
  accept_privacy_statement: string[],
  message: string
}

interface IRegisterErrorResponseData {
  errors: IRegisterErrors,
  status: number,
  title: string,
}

function nameof<T>(key: keyof T, instance?: T): keyof T {
  return key;
}

const emptyErrors = {
  name: [],
  username: [],
  email: [],
  password: [],
  confirm_password: [],
  birthday: [],
  accept_terms_and_conditions: [],
  accept_privacy_statement: [],
  message: '',
} as IRegisterErrors;

const emptyState = {
  firstName: '',
  lastName: '',
  nickname: '',
  password: '',
  passwordRepeat: '',
  email: '',
  birthdate: {
    day: '',
    month: '',
    year: '',
  },
  isLoading: false,
  acceptTerms: false,
  acceptPrivacy: false,
  acceptNewsletter: false,
  registerErrors: emptyErrors,
  registrationSuccess: false,
} as IRegisterState;

export default class RegisterForm extends Component<any, IRegisterState> {
  private recaptchaRef = createRef<ReCAPTCHA>();
  
  constructor(prop: any) {
    super(prop);

    this.state = emptyState;
  }

  handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const target = event.target;
    const value = target.value;
    const name = target.name as 'firstName' | 'lastName' | 'nickname' | 'password' | 'passwordRepeat' | 'email';

    this.setState({
      [name]: value,
    });
  }

  handleCheckBoxChange(event: FormEvent, data: CheckboxProps) {
    const name = data.name as 'acceptTerms' | 'acceptPrivacy' | 'acceptNewsletter';

    this.setState({
      [name]: !this.state[name],
    });
  }

  handleDropdownChange(event: SyntheticEvent, data: DropdownProps) {
    const name = data.name as 'day' | 'month' | 'year';
    const value = data.value as number | '';

    this.setState({
      birthdate: {
        ...this.state.birthdate,
        [name]: value,
      },
    });
  }

  handleSubmit() {
    const {
      isLoading, firstName, lastName, nickname, email, password, passwordRepeat, acceptTerms, acceptPrivacy, birthdate,
      acceptNewsletter
    } = this.state;

    if (isLoading) {
      return;
    }

    let name = firstName ? firstName.trim() : '';
    if (lastName && lastName.trim().length > 0) {
      name += ` ${lastName.trim()}`;
    }

    let checkBirthDate = null;

    if (birthdate && birthdate.year !== '' && birthdate.month !== '' && birthdate.day !== '') {
      checkBirthDate = new Date(Date.UTC(
        birthdate.year as number,
        birthdate.month as number - 1,
        birthdate.day as number,
      ));
    }

    this.setState({
      isLoading: true,
    });

    axios.post('/api/authenticate/register', {
      name: name,
      username: nickname,
      email: email,
      password: password,
      confirm_password: passwordRepeat,
      birthday: checkBirthDate,
      accept_terms_and_conditions: acceptTerms,
      accept_privacy_statement: acceptPrivacy,
      subscribe_newsletter: acceptNewsletter,
      google_recaptcha_value: this.recaptchaRef.current ? this.recaptchaRef.current.getValue() : '',
    })
      .then(response => {
        let successState = emptyState;
        successState.registrationSuccess = true;

        this.setState(successState);
      })
      .catch(error => {
        const data = error.response.data as IRegisterErrorResponseData;

        if (data.status === 400) {
          this.setState({
            registerErrors: data.errors,
          });
        } else if(error.response.status === 500) {
          let newErrors = emptyErrors;
          newErrors.message = 'Serverfehler';
          this.setState({
            registerErrors: newErrors
          });
        }
      })
      .finally(() => {
        if(this.recaptchaRef.current) {
          this.recaptchaRef.current.reset();
        }
        this.setState({
          isLoading: false,
        });
      });
  }

  handleDismiss() {
    this.setState({
      registrationSuccess: false,
    });
  };

  render() {
    const {
      firstName, lastName, nickname, password, passwordRepeat, email, birthdate, isLoading, acceptPrivacy,
      acceptTerms, acceptNewsletter, registerErrors, registrationSuccess
    } = this.state;

    const errors = registerErrors ? registerErrors : emptyErrors;

    let dayList: DropdownItemProps[] = [];
    for (let i = 1; i <= 31; i++) {
      dayList.push({key: i, value: i, text: i});
    }

    let monthList: DropdownItemProps[] = [
      {key: 1, value: 1, text: 'Januar'},
      {key: 2, value: 2, text: 'Februar'},
      {key: 3, value: 3, text: 'März'},
      {key: 4, value: 4, text: 'April'},
      {key: 5, value: 5, text: 'Mai'},
      {key: 6, value: 6, text: 'Juni'},
      {key: 7, value: 7, text: 'Juli'},
      {key: 8, value: 8, text: 'August'},
      {key: 9, value: 9, text: 'September'},
      {key: 10, value: 10, text: 'Oktober'},
      {key: 11, value: 11, text: 'November'},
      {key: 12, value: 12, text: 'Dezember'},
    ];

    let yearList: DropdownItemProps[] = [];
    for (let i = new Date().getFullYear() - 16; i >= new Date().getFullYear() - 100; i--) {
      yearList.push({key: i, value: i, text: i});
    }

    return (
      <Form
        inverted
        loading={isLoading}
        success={registrationSuccess}
        error={!!errors.message && errors.message.length > 0}
        onSubmit={() => {
          if (this.recaptchaRef && this.recaptchaRef.current) {
            this.recaptchaRef.current.execute();
          }
        }
        }
          >
        <Header as='h1' textAlign='center' inverted>
          Registrieren
        </Header>
        <Container text>
          {/* <CoronaInfo/> */}
          <Header as='h3' dividing inverted>
            Benutzerinformationen
          </Header>
          <Message info size='mini'>
            Bitte gib deinen Namen wie auf deinem Ausweis ein. Wir benötigen diesen für die Identitätskontrolle am
            Einlass.
          </Message>
          <Form.Group widths='equal'>
            <Form.Field required error={errors.name && errors.name.length > 0}>
              <label>Vorname</label>
              <Input
                name={nameof<IRegisterState>('firstName')}
                value={firstName}
                onChange={(event) => this.handleInputChange(event)}
                placeholder='Max'
              />
              {errors.name && errors.name.length > 0 &&
              <Label basic color='red' pointing>
                {errors.name.map((x, k) => <p key={k}>{x}</p>)}
              </Label>
              }
            </Form.Field>
            <Form.Input
              name={nameof<IRegisterState>('lastName')}
              label='Nachname'
              value={lastName}
              onChange={(event) => this.handleInputChange(event)}
              placeholder='Muster'
            />
          </Form.Group>
          <Form.Group widths={2}>
            <Form.Field required error={errors.username && errors.username.length > 0}>
              <label>Nickname</label>
              <Input
                name={nameof<IRegisterState>('nickname')}
                value={nickname}
                onChange={(event) => this.handleInputChange(event)}
                placeholder='M4x th3 H4x0r'
              />
              {errors.username && errors.username.length > 0 &&
              <Label basic color='red' pointing>
                {errors.username.map((x, k) => <p key={k}>{x}</p>)}
              </Label>
              }
            </Form.Field>
            <Form.Field required>
              <label>Geburtstag</label>
              <Form.Group widths={3} className='birthdate'>
                <Form.Field width={5} required>
                  <Dropdown
                    name={nameof<IBirthdate>('day')}
                    onChange={(event, data) => this.handleDropdownChange(event, data)}
                    value={(birthdate && birthdate.day) ? birthdate.day : ''}
                    placeholder='Tag'
                    clearable
                    fluid
                    search
                    selection
                    options={dayList}
                    error={errors.birthday && errors.birthday.length > 0}
                  />
                </Form.Field>
                <Form.Field width={6} required>
                  <Dropdown
                    name={nameof<IBirthdate>('month')}
                    onChange={(event, data) => this.handleDropdownChange(event, data)}
                    value={(birthdate && birthdate.month) ? birthdate.month : ''}
                    placeholder='Monat'
                    clearable
                    fluid
                    search
                    selection
                    options={monthList}
                    error={errors.birthday && errors.birthday.length > 0}
        
                  />
                </Form.Field>
                <Form.Field width={5} required>
                  <Dropdown
                    name={nameof<IBirthdate>('year')}
                    onChange={(event, data) => this.handleDropdownChange(event, data)}
                    value={(birthdate && birthdate.year) ? birthdate.year : ''}
                    placeholder='Jahr'
                    clearable
                    fluid
                    search
                    selection
                    options={yearList}
                    error={errors.birthday && errors.birthday.length > 0}
                  />
                </Form.Field>
              </Form.Group>
              {errors.birthday && errors.birthday.length > 0 &&
              <Label basic color='red' pointing>
                {errors.birthday.map((x, k) => <p key={k}>{x}</p>)}
              </Label>
              }
            </Form.Field>
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field required error={errors.password && errors.password.length > 0}>
              <label>Passwort</label>
              <Input
                type='password'
                name={nameof<IRegisterState>('password')}
                value={password}
                onChange={(event) => this.handleInputChange(event)}
                placeholder='Passwort'
              />
              {errors.password && errors.password.length > 0 &&
              <Label basic color='red' pointing>
                {errors.password.map((x, k) => <p key={k}>{x}</p>)}
              </Label>
              }
            </Form.Field>
            <Form.Field required error={errors.confirm_password && errors.confirm_password.length > 0}>
              <label>Passwort wiederholen</label>
              <Input
                type='password'
                name={nameof<IRegisterState>('passwordRepeat')}
                value={passwordRepeat}
                onChange={(event) => this.handleInputChange(event)}
                placeholder='Passwort wiederholen'
              />
              {errors.confirm_password && errors.confirm_password.length > 0 &&
              <Label basic color='red' pointing>
                {errors.confirm_password.map((x, k) => <p key={k}>{x}</p>)}
              </Label>
              }
            </Form.Field>
          </Form.Group>
          <Form.Field required error={errors.email && errors.email.length > 0}>
            <label>E-Mail</label>
            <Input
              type='mail'
              name={nameof<IRegisterState>('email')}
              value={email}
              onChange={(event) => this.handleInputChange(event)}
              placeholder='max@muster.mail'
            />
            {errors.email && errors.email.length > 0 &&
            <Label basic color='red' pointing>
              {errors.email.map((x, k) => <p key={k}>{x}</p>)}
            </Label>
            }
          </Form.Field>
          <Header as='h4' dividing inverted>Datenschutz und AGB</Header>
          <Form.Field required error={errors.accept_privacy_statement && errors.accept_privacy_statement.length > 0}>
            <Checkbox
              name={nameof<IRegisterState>('acceptPrivacy')}
              label={<label>Ich akzeptiere die <Link to='/Datenschutz'>Datenschutzerklärung</Link>.</label>}
              onChange={(event, data) => this.handleCheckBoxChange(event, data)}
              checked={acceptPrivacy}
            /><br/>
            {errors.accept_privacy_statement && errors.accept_privacy_statement.length > 0 &&
            <Label basic color='red' pointing>
              {errors.accept_privacy_statement.map((x, k) => <p key={k}>{x}</p>)}
            </Label>
            }
          </Form.Field>
          <Form.Field required error={errors.accept_terms_and_conditions && errors.accept_terms_and_conditions.length > 0}>
            <Checkbox
              name={nameof<IRegisterState>('acceptTerms')}
              label={<label>Ich akzeptiere die <Link to='/AGB'>AGB</Link> für die Clanwars 2024.</label>}
              onChange={(event, data) => this.handleCheckBoxChange(event, data)}
              checked={acceptTerms}
            /><br/>
            {errors.accept_terms_and_conditions && errors.accept_terms_and_conditions.length > 0 &&
            <Label basic color='red' pointing>
              {errors.accept_terms_and_conditions.map((x, k) => <p key={k}>{x}</p>)}
            </Label>
            }
          </Form.Field>
          <Form.Field>
            <Checkbox
              name={nameof<IRegisterState>('acceptNewsletter')}
              label={<label>Ich möchte den Newsletter abonieren um in Zukunft immer auf dem Laufenden zu
                bleiben.<br/><small>Wir senden dir ausschließlich Informationen zu künftigen Clanwars-Veranstaltungen. Du
                kannst dich jederzeit wieder von dem Newsletter abmelden.</small></label>}
              onChange={(event, data) => this.handleCheckBoxChange(event, data)}
              checked={acceptNewsletter}
            /><br/>
          </Form.Field>
        </Container>
        <br/>
        <Container textAlign='center'>
          <ReCAPTCHA
            ref={this.recaptchaRef}
            size='invisible'
            sitekey={Config.RecaptchaKey}
            onChange={() => this.handleSubmit()}
            badge='inline'
          /><br/>
          <Button color='orange' size='big'>
            Registrieren
          </Button>
        </Container><br/><br/>
        <Container>
          <Message
            success
            onDismiss={() => this.handleDismiss()}
          >
            <Message.Header>Registrierung erfolgreich</Message.Header>
            <Message.Content>
              <p>Du solltest eine E-Mail mit einem <strong>Aktivierungslink</strong> erhalten haben. Bitte prüfe deine Mails und folge dem Link.</p>
              <p>Die Aktivierung ist 24 Stunden möglich. Sollte innerhalb dieser Zeit keine Bestätigung erfolgen werden die Daten aus Datenschutzgründen aus unserem System gelöscht.</p>
            </Message.Content>
          </Message>
          <Message
            error
            >
            <Message.Header>Ups, da ist leider etwas schief gelaufen</Message.Header>
            <Message.Content>
              <p>Es scheint gerade ein Problem mit dem Server zu geben.</p>
              <p>Bitte schaue später noch einmal vorbei und versuche es noch einmal. Sollte es weiter Probleme geben, kontaktiere uns bitte.</p>
            </Message.Content>
          </Message>
        </Container>
        {/*<Newsletter/>*/}
        {/* <Container>
          <Message info>
            <Message.Header>Online-Registrierung ist noch nicht gestartet.</Message.Header>
            <Message.Content>
              Aktuell klären wir, ob und in welchem Umfang die Clanwars 2024, in der aktuellen COVID-19 Situation, stattfinden kann.<br/>
              Sobald wir Genaueres wissen, werden wir euch umgehend hier, sowie über unsere Facebook-Seite darüber informieren.
            </Message.Content>
          </Message>
        </Container>
        */}
      </Form>
    );
  }
}