import React, { ChangeEvent, Component } from 'react';
import { Button, Label, Container, Form, Header, Input, Message } from 'semantic-ui-react';
import { Link, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';

interface RouteInfo {
  mail?: string,
  code?: string,
}

interface VerificationProps extends RouteComponentProps<RouteInfo> {
  
}

interface IVerificationState {
  mail: string,
  code: string,
  isLoading: boolean,
  isSuccess: boolean,
  verificationErrors?: IVerificationErrors,
}


interface IVerificationErrors {
  email: string[],
  verification_id: string[],
  message: string,
}

interface IVerificationErrorResponseData {
  message: string,
  errors: IVerificationErrors,
  status: number,
  title: string,
}

const emptyState = {
  mail: '',
  code: '',
  isLoading: false,
  isSuccess: false,
  verificationErrors: {}
} as IVerificationState;

export default class Verification extends Component<VerificationProps, IVerificationState> {
  constructor(props: VerificationProps) {
    super(props);
    
    this.state = emptyState;
  }
  
  postVerification(): void{
    const {mail, code, isLoading} = this.state;
    
    if(isLoading) {
      return;
    }
    
    this.setState({
      isLoading: true
    });
    
    axios.post('/api/authenticate/verify-registration', {
      email: mail,
      verification_id: code,
    }).then(response => {
      let newState = emptyState;
      newState.isSuccess = true;
      
      this.setState(newState);
    }).catch(error => {
      const data = error.response.data as IVerificationErrorResponseData;
      
      if(data.status === 400) {
        this.setState({
          verificationErrors: data.errors ? data.errors : {} as IVerificationErrors
        });
      } else if(error.response.data.message) {
        this.setState({
          verificationErrors: {
            email: [],
            verification_id: [],
            message: error.response.data.message
          } as IVerificationErrors
        });
      }
    }).finally(() => {
        this.setState({
          isLoading: false,
        })
    });
  }

  handleMailChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      mail: event.target.value
    });
  }

  handleCodeChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      code: event.target.value
    });
  }

  componentDidMount(): void {
    const {mail, code} = this.props.match.params;

    this.setState({
      mail: mail ? mail : '',
      code: code ? code : '',
    }, () => {
      if (mail && mail.length > 0 &&
        code && code.length > 0) {
        this.postVerification();
      }
    });
  }

  render() {
    const {mail, code, isLoading, isSuccess, verificationErrors} = this.state;
    
    const errors = verificationErrors ? verificationErrors : {} as IVerificationErrors;
    
    return (
      <Form
        inverted
        loading={isLoading}
        success={isSuccess}
        error={(!!errors.message && errors.message.length > 0)}
        onSubmit={() => this.postVerification()}
      >
        <Header as='h1' textAlign='center' inverted>
          E-Mail bestätigen
        </Header>
        {/*<Container text>*/}
        {/*  <Form.Group widths='equal'>*/}
        {/*    <Form.Field required>*/}
        {/*      <label>E-Mail Adresse</label>*/}
        {/*      <Input*/}
        {/*        placeholder='max@muster.mail'*/}
        {/*        value={mail}*/}
        {/*        onChange={(event) => this.handleMailChange(event)}*/}
        {/*      />*/}
        {/*      {errors.email && errors.email.length > 0 &&*/}
        {/*      <Label basic color='red' pointing>*/}
        {/*        {errors.email.map((x, k) => <p key={k}>{x}</p>)}*/}
        {/*      </Label>*/}
        {/*      }*/}
        {/*    </Form.Field>*/}
        {/*    <Form.Field required>*/}
        {/*      <label>Code</label>*/}
        {/*      <Input*/}
        {/*        placeholder='Verifizierungscode'*/}
        {/*        value={code}*/}
        {/*        onChange={(event) => this.handleCodeChange(event)}*/}
        {/*      />*/}
        {/*      {errors.verification_id && errors.verification_id.length > 0 &&*/}
        {/*        <Label basic color='red' pointing>*/}
        {/*          {errors.verification_id.map((x, k) => <p key={k}>{x}</p>)}*/}
        {/*        </Label>*/}
        {/*      }*/}
        {/*    </Form.Field>*/}
        {/*  </Form.Group>*/}
        {/*  <Form.Group widths='equal'>*/}
        {/*    <Form.Field>*/}
        {/*      <Button color='orange' size='big' className='verify-button' >*/}
        {/*        Bestätigen*/}
        {/*      </Button>*/}
        {/*    </Form.Field>*/}
        {/*    <Form.Field>*/}
        {/*      <Link to='/' className='ui big orange inverted button'>*/}
        {/*        Zur Hauptseite*/}
        {/*      </Link>*/}
        {/*    </Form.Field>*/}
        {/*  </Form.Group>*/}
        {/*</Container>*/}
        <Container textAlign='center'>
          <Message error>
            <Message.Header>Die E-Mail Adresse konnte nicht bestätigt werden</Message.Header>
            <p>Vermutlich ist dein Bestätigungs-Code abgelaufen.</p>
            <p>Dieser ist nur 24 Stunden nach der Registrierung gültig. Sollte dies der Fall sein, registriere dich bitte noch einmal.</p>
            <p>Solltest du andere Probleme mit der Registrierung haben, kannst du uns auch gern kontaktieren.</p>
          </Message>
          <Message success>
            <Message.Header>Verifizierung erfolgreich</Message.Header>
            <Message.Content>
              <p>Deine Registrierung ist damit abgeschlossen. Du kannst dich nun einloggen und zur Clanwars anmelden.</p>
            </Message.Content>
          </Message>
          <Link to='/' className='ui big orange inverted button'>
            Zur Hauptseite
          </Link>
        </Container>
      </Form>
    );
  }
}