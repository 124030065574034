import React, {Component} from 'react';
import {Card, Container, Grid, Header, Icon, Image} from 'semantic-ui-react';

export default class Games extends Component {
  render() {
    return (
      <div className='games section dark' id='Spiele'>
        <Container textAlign='center'>
          <Header as='h1'>
            Spiele
            <Header.Subheader>Welche Spiele werden auf der Clanwars 2023 gespielt?</Header.Subheader>
          </Header>
          <Grid stackable columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/games/csgo.jpg'/>
                  <Card.Content>
                    <Card.Header>Counter-Strike: Global Offensive</Card.Header>
                    <Card.Description>
                      Der Taktikshooter aus dem Hause Valve
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/games/lol.jpg'/>
                  <Card.Content>
                    <Card.Header>League of Legends</Card.Header>
                    <Card.Description>
                      Das beliebte MOBA
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/games/battlefield.jpg'/>
                  <Card.Content>
                    <Card.Header>Battlefield 4/5</Card.Header>
                    <Card.Description>
                      First Person Shooter der extra Klasse
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/games/rocket.jpg'/>
                  <Card.Content>
                    <Card.Header>Rocket League</Card.Header>
                    <Card.Description>
                      Fußball mit Turbo-betriebenen Auto
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/games/blobby.jpg'/>
                  <Card.Content>
                    <Card.Header>Blobby Volley</Card.Header>
                    <Card.Description>
                      Das ultimative LAN-Game
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/games/flatout.jpg'/>
                  <Card.Content>
                    <Card.Header>FlatOut 2</Card.Header>
                    <Card.Description>
                      Crash-Test extrem
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}