import React, {Component} from 'react';
import { Container, Header, List } from 'semantic-ui-react';
import Contact from '../Layout/Contact';
// import CoronaInfo from '../Landing/CoronaInfo';

export default class Faq extends Component {
  render() {
    return(
      <div className='internal-infos section dark' id='FAQ'>
        <Container>
          <Header as='h2'>
            Häufig gestellte Fragen
          </Header>
          {/* <CoronaInfo/> */}
          <List divided relaxed>
            <List.Item>
              <List.Header>Wann findet die Clanwars statt?</List.Header>
              <List.Description>Vom 18.10.2024 bis zum 20.10.2024 - Einlass ist am 18.10. ab 18 Uhr</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Welche Turniere werden stattfinden?</List.Header>
              <List.Description>Aktuell gibt es noch keine genaue Turnierliste, aber grundsätzlich wird sich im Vergleich zu den letzten Jahren nichts groß verändern.</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Was muss ich mitbringen?</List.Header>
              <List.Description>
                <ul>
                  <li>Was du zum Zocken brauchst (PC/Laptop, Monitor, Maus, Tastatur und die entsprechenden Kabel)</li>
                  <li>Ein ca. 5m Netzwerkkabel</li>
                  <li>Eine Verteilerdose</li>
                  <li>Schlafzeug (Schlafsack + Isomatte)</li>
                </ul>
              </List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Gibt es Internet auf der Clanwars?</List.Header>
              <List.Description>Ja, dennoch bitten wir euch, vor der Clanwars noch einmal eure Spiele zu aktualisieren.</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Gibt es einen Schlafsaal?</List.Header>
              <List.Description>Wir haben einen extra Raum, indem ihr schlafen könnt.</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Wie sieht es mit der Verpflegung aus?</List.Header>
              <List.Description>Wir werden wie gewohnt eine Vielzahl an Getränken und Speisen zu sehr günstigen Preisen anbieten. Habt ihr einen besonderen Getränkewunsch, schreibt uns einfach an und wir klären was wir da machen können. Eine genaue Speisekarte wird vor der Clanwars noch bekannt gegeben.</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Wie kann ich den komischen Avatar in der Clan-Übersicht ändern?</List.Header>
              <List.Description>Im Moment ist das Bild nur ein Platzhalter und lässt sich nicht ändern.</List.Description>
            </List.Item>
            <List.Item>
              <List.Header>Ich habe noch eine Frage, die hier nicht beantwortet wurde.</List.Header>
              <List.Description>Dann schreibe uns über das <Contact/>-Formular und wir werden uns bei dir melden.</List.Description>
            </List.Item>
          </List>
        </Container>
      </div>
    );
  }
}
