import React, {Component} from 'react';
import { Container, Header, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default class Success extends Component {
  render() {
    return (
      <Container className='section'>
        <Header>Bezahlung erfolgreich</Header>
        Vielen Dank für deine Anmeldung.<br/>
        <Link to='/'>Zurück zur Hauptseite</Link>
      </Container>
    );
  }
}