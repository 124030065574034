import React, {Component} from 'react';
import {Card, Container, Grid, Header, Image} from 'semantic-ui-react';

export default class Gallery extends Component {
  render() {
    return (
      <div className='gallery section light' id='Galerie'>
        <Container textAlign='center'>
          <Header as='h1'>
            Galerie
            <Header.Subheader>Hier kannst du dir schon mal ein Bild machen.</Header.Subheader>
          </Header>
          <Grid doubling columns={4}>
            <Grid.Row>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/gallery/1.jpg' />
                  <Card.Content>
                    <Card.Description>
                      Die Location von außen
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/gallery/2.jpg' />
                  <Card.Content>
                    <Card.Description>
                      Der Blick von der Bar
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/gallery/3.jpg' />
                  <Card.Content>
                    <Card.Description>
                      Hardware darf nicht fehlen
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/gallery/4.jpg' />
                  <Card.Content>
                    <Card.Description>
                      Bei Bedarf ist die Empore geöffnet
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/gallery/5.jpg' />
                  <Card.Content>
                    <Card.Description>
                      Zocken!
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/gallery/6.jpg' />
                  <Card.Content>
                    <Card.Description>
                      Durstlöscher
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/gallery/7.jpg' />
                  <Card.Content>
                    <Card.Description>
                      Blick in Richtung Bar
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
              <Grid.Column>
                <Card fluid>
                  <Image src='/images/gallery/8.jpg' />
                  <Card.Content>
                    <Card.Description>
                      Wir sehen uns auf der Clanwars
                    </Card.Description>
                  </Card.Content>
                </Card>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    )
  }
}