import React, {Component} from 'react';
import RegisterForm from './RegisterForm';
import { Route } from 'react-router';
import Verification from './Verification';
import VerifyPasswordReset from './VerifyPasswordReset';

export default class Register extends Component {
  render() {
    return (
      <div id='Anmeldung' className='register section light'>
        <Route exact path='/' component={RegisterForm}/>
        <Route path='/verifizieren/:code?/:mail?' component={Verification} />
        <Route path='/passwort/:code?/:mail?' component={VerifyPasswordReset} />
      </div>
    )
  }
}