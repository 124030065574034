import React, {Component} from 'react';
import {Container, Header} from 'semantic-ui-react';

export default class Map extends Component {
  render() {
    return (
      <div className='map section light' id='Karte'>
        <Container textAlign='center'>
          <Header as='h1'>
            Anfahrt
            <Header.Subheader>Hier findet die Clanwars statt.</Header.Subheader>
          </Header>
          <p className='text-muted'>
            Kulturforum Stadtpark Frankenberg<br />
            Hammertal 3<br />
            09669 Frankenberg/Sa.
          </p>
          <div className='google-maps'>
            <iframe title='google-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d40259.7319313727!2d13.002787673490245!3d50.900900154461894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a743ac3c850dd1%3A0xe11d645441a77dd6!2sHammertal+3%2C+09669+Frankenberg%2FSachsen%2C+Deutschland!5e0!3m2!1sde!2sus!4v1448538636923" height="500" frameBorder="0"></iframe>
          </div>
        </Container>
      </div>
    );
  }
}