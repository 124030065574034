import React, { ChangeEvent, Component, createRef } from 'react';
import { Form, Input, Label, Message, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Config from '../../Config';
import axios from 'axios';

interface IResetPasswordProps {
  onClose: Function,
}

interface IResetPasswordState {
  email: string,
  isLoading: boolean,
  success: boolean,
  serverError: boolean,
  errors: IResetPasswordErrors,
}

interface IResetPasswordErrors {
  email: string[],
  google_recaptcha_value: string[],
}

const emptyState = {
  email: '',
  isLoading: false,
  success: false,
  serverError: false,
  errors: {} as IResetPasswordErrors,
} as IResetPasswordState;

export default class ResetPassword extends Component<IResetPasswordProps, IResetPasswordState> {
  private recaptchaRef = createRef<ReCAPTCHA>();

  constructor(props: IResetPasswordProps) {
    super(props);

    this.state = emptyState;
  }

  handleMailChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      email: event.target.value
    });
  }

  handleClose() {
    this.props.onClose();
  }

  handleSubmit() {
    const {email, isLoading} = this.state;

    if(isLoading) {
      return;
    }

    this.setState({
      isLoading: true
    });

    axios.post('/api/authenticate/forgot-password', {
      email: email,
      google_recaptcha_value: this.recaptchaRef.current
        ? this.recaptchaRef.current.getValue()
        : '',
    }).then(response => {
      let newState = emptyState;
      emptyState.success = true;
      this.setState(newState);
    }).catch(error => {
      if(error.response.status === 400) {
        const errors = error.response.data.errors as IResetPasswordErrors;
        this.setState({
          errors: errors
        });
      } else {
        this.setState({
          serverError: true,
        })
      }
      this.setState({
        serverError: true,
      })
    }).finally(() => {
      if(this.recaptchaRef.current) {
        this.recaptchaRef.current.reset();
      }

      this.setState({
        isLoading: false,
      });
    });
  }

  render() {
    const {email, isLoading, success, serverError, errors} = this.state;
    return (
      <Modal
        trigger={<Link to='/'>Passwort vergessen</Link>}
        centered={false}
        size='mini' onClose={() => this.handleClose()}
      >
        <Modal.Header>Passwort zurücksetzen</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              loading={isLoading}
              success={success}
              error={serverError}
              onSubmit={() => {
                if(this.recaptchaRef.current) {
                  this.recaptchaRef.current.execute();
                }
              }}
            >
              <Form.Field required>
                <Input
                  type='email'
                  value={email}
                  onChange={(event) => this.handleMailChange(event)}
                  placeholder='E-Mail Adresse'
                  required
                />
                {errors.email && errors.email.length > 0 && <Label basic color="red" pointing>
                  {errors.email.map((x, k) => <p key={k}>{x}</p>)}
                </Label>}
              </Form.Field>
              <Form.Field>
                <Form.Button color='orange'>
                  Passwort zurücksetzen
                </Form.Button>
              </Form.Field>
              <Form.Field>
                <ReCAPTCHA
                  ref={this.recaptchaRef}
                  size='invisible'
                  sitekey={Config.RecaptchaKey}
                  badge='inline'
                  onChange={() => this.handleSubmit()}
                />
                {errors.google_recaptcha_value && errors.google_recaptcha_value.length > 0 &&
                  <Label basic color='red' pointing>
                    {errors.google_recaptcha_value.map((x, k) => <p key={k}>{x}</p>)}
                  </Label>
                }
              </Form.Field>
              <Message
                success
                header='Anfrage abgeschickt'
                content='Bitte überprüfe deine E-Mails. Du solltest einen Link zum Zurücksetzen deines Passworts erhalten haben. Dieser ist 24 Stunden gültig.'
              />
              <Message
                error
                header='Upps, da ist etwas schief gelaufen'
                content='Bei deiner Anfrage hat etwas nicht funktioniert. Bitte versuche es später noch einmal.'
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}