import React, {Component} from 'react';
import NoMember from './NoMember';
import Member from './Member';
import axios from 'axios'
import Payment from './Payment';
import { IPaymentOverviewDetails } from './PaymentOverviewModal';
import { UserInfo } from '../../types';
import Infos from './Infos';
import Faq from './Faq';

export interface IClanProps {
  paymentDetails: IPaymentOverviewDetails,
  userInfo: UserInfo,
  setUserClanState: ((inClan: boolean) => void),
}


interface IClanState {
  clanInformation: IClanInformation,
  isLoading: boolean,
  initialLoad: boolean,
}

interface IClanInformation {
  no_clan: IInNoClan,
  clan: IInClan,
}

interface IInNoClan {
  clans: IClan[],
}

interface IClanMember {
  id: string,
  name: string,
  join_date: Date,
  payed: boolean,
  is_admin: boolean,
}

export interface IInClan {
  name: string,
  members: IClanMember[],
  is_admin: boolean,
}
export interface IClan {
  id: string,
  name: string,
}

const emptyState = {
  clanInformation: {
    clan: {} as IInClan,
    no_clan: {} as IInNoClan,
  } as IClanInformation,
  isLoading: false,
  initialLoad: false,
} as IClanState;

export default class Clan extends Component<IClanProps, IClanState> {
  constructor(props: IClanProps) {
    super(props);
    
    let newState = emptyState;
    newState.initialLoad = true;
    
    this.state = newState;
  }
  
  
  private getClanInfo(): void {
    const {isLoading} = this.state;
    const {setUserClanState} = this.props;
    
    if(isLoading) {
      return;
    }
    
    this.setState({
      isLoading: true,
    });
    
    // Get clan information from the server
    axios.get('/api/clans')
      .then(response => {
        const data = response.data as IClanInformation;
        if(data) {
          this.setState({
            clanInformation: data,
          });

          setUserClanState(!!data.clan);
        } else {
          console.error('mapping error');
        }
      })
      .catch(error => {
        console.error('error', error)
      })
      .finally(() => {
        this.setState({
          isLoading: false,
          initialLoad: false,
        });
      });
    
  }
  
  componentDidMount(): void {
    // Get clan information from the server
    this.getClanInfo();
  }
  
  render() {
    const {isLoading, initialLoad, clanInformation} = this.state;
    const {paymentDetails, userInfo} = this.props;
    
    const clans = (clanInformation && clanInformation.no_clan)
      ? clanInformation.no_clan.clans
      : [] as IClan[];
    
    const clan = (clanInformation && clanInformation.clan)
      ? clanInformation.clan
      : {} as IInClan;

    let render = <div>&nbsp;</div>;
    
    if(!isLoading && !initialLoad && clanInformation) {
      render = clanInformation.clan
        ? <Member clan={clan} userInfo={userInfo} paymentDetails={paymentDetails} />
        : <NoMember clans={clans} updateClan={() => this.getClanInfo()}/>;
    }
    
    return (
      <div className='internal'>
        <div className='section dark' id='Clan'>
          {render}
        </div>
        {!isLoading && <Payment paymentDetails={paymentDetails} />}
        {!isLoading && <Faq/>}
      </div>
    );
  }
}