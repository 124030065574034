import React, {Component} from 'react';
import {Button, Container, Divider, Dropdown, Form, Grid, Header, Icon, Input, Message, Label, Segment} from 'semantic-ui-react';
import { IClan } from '../index';
import axios from 'axios';

export interface INoMemberProps {
  clans: IClan[],
  updateClan: (() => void),
}

interface IJoinClanErrors {
  password: string[],
}
interface IJoinClanInfo {
  id: string,
  password: string,
  showPassword: boolean,
  isLoading: boolean,
  serverError: boolean,
  errors: IJoinClanErrors,
}
const emptyJoinClan = {
  id: '',
  password: '',
  showPassword: false,
  isLoading: false,
  serverError: false,
  errors: {} as IJoinClanErrors,
} as IJoinClanInfo;


interface ICreateClanErrors {
  name: string[],
  password: string[],
}
interface ICreateClanInfo {
  name: string,
  password: string,
  showPassword: boolean,
  isLoading: boolean,
  serverError: boolean,
  errors: ICreateClanErrors,
}
interface INoMemberState {
  joinClan: IJoinClanInfo,
  createClan: ICreateClanInfo,
}

const emptyCreateClan = {
  name: '',
  password: '',
  showPassword: false,
  isLoading: false,
  serverError: false,
  errors: {} as ICreateClanErrors,
} as ICreateClanInfo;

const emptyState = {
  joinClan: emptyJoinClan,
  createClan: emptyCreateClan,
} as INoMemberState;

export default class NoMember extends Component<INoMemberProps, INoMemberState> {
  constructor(props: INoMemberProps) {
    super(props);

    this.state = emptyState;
  }

  handleJoinClanIdChange(id: string) {
    this.setState(prev => ({
      joinClan: {
        ...prev.joinClan,
        id: id,
      }
    }));
  }

  handleJoinClanPasswordChange(password: string) {
    this.setState(prev => ({
      joinClan: {
        ...prev.joinClan,
        password: password,
      }
    }));
  }

  handleJoinClanShowPasswordToggle() {
    this.setState(prev => ({
      joinClan: {
        ...prev.joinClan,
        showPassword: !prev.joinClan.showPassword,
      }
    }));
  }

  handleCreateClanNameChange(name: string) {
    this.setState(prev => ({
      createClan: {
        ...prev.createClan,
        name: name,
      }
    }));
  }

  handleCreateClanPasswordChange(password: string) {
    this.setState(prev => ({
      createClan: {
        ...prev.createClan,
        password: password,
      }
    }));
  }

  handleCreateClanShowPasswordToggle() {
    this.setState(prev => ({
      joinClan: {
        ...prev.joinClan,
        showPassword: !prev.joinClan.showPassword,
      }
    }));
  }
  
  handleJoinClan() {
    const {joinClan} = this.state;
    const {updateClan} = this.props;
    
    if(joinClan.isLoading) {
      return;
    }
    
    this.setState(prev => ({
      joinClan: {
        ...prev.joinClan,
        isLoading: true,
      } 
    }));
    
    axios.put('/api/clans/' + joinClan.id, {
      password: joinClan.password,      
    }).then(response => {
      updateClan();
    }).catch(error => {
      const errors = error.response.data.errors as IJoinClanErrors;
      if(errors) {
        this.setState(prev => ({
          joinClan: {
            ...prev.joinClan,
            errors: errors,
          }
        }));
      } else {
        this.setState(prev => ({
          joinClan: {
            ...prev.joinClan,
            errors: {} as IJoinClanErrors,
            serverError: true,
          }
        }));
      }
    }).finally(() => {
      this.setState(prev => ({
        joinClan: {
          ...prev.joinClan,
          isLoading: false,
        }
      }));
    });
  }

  handleCreateClan() {
    const {createClan} = this.state;
    const {updateClan} = this.props;

    if(createClan.isLoading) {
      return;
    }

    this.setState(prev => ({
      createClan: {
        ...prev.createClan,
        isLoading: true,
      }
    }));

    axios.post('/api/clans', {
      name: createClan.name,
      password: createClan.password,
    }).then(response => {
      updateClan();
    }).catch(error => {
      const errors = error.response.data.errors as ICreateClanErrors;
      if(errors) {
        this.setState(prev => ({
          createClan: {
            ...prev.createClan,
            errors: errors,
          }
        }));
      } else {
        this.setState(prev => ({
          createClan: {
            ...prev.createClan,
            errors: {} as ICreateClanErrors,
            serverError: true,
          }
        }));
      }
    }).finally(() => {
      this.setState(prev => ({
        createClan: {
          ...prev.createClan,
          isLoading: false,
        }
      }));
    });
  }

  render() {
    const {joinClan, createClan} = this.state;
    const { clans } = this.props;
    
    const clanOptions = clans.map((clan) => ({
      key: clan.id,
      text: clan.name,
      value: clan.id,
    }));

    return (
      <Container>
        <Message info>
          <Header as='h2'>Du bist derzeit in keinem Clan</Header>
          <p>
            Wir empfehlen dir einem Clan beizutreten, oder einen anzulegen, damit wir dich beim Erstellen
            des Sitzplanes bei deinen Freunden platzieren können.
          </p>
        </Message>
        <Container text>
          <Segment placeholder>
            <Grid columns={2} stackable textAlign='center'>
              <Divider vertical>Oder</Divider>
              <Grid.Row verticalAlign='middle'>
                <Grid.Column>
                  <Header icon>
                    <Icon name='sign in'/>
                    Tritt einem Clan bei
                  </Header>
                  <Form
                    loading={joinClan.isLoading}
                    error={joinClan.serverError}
                    onSubmit={() => this.handleJoinClan()}
                  >
                    <Form.Field required>
                      <label>Clan auswählen</label>
                      <Dropdown
                        placeholder='Clan suchen'
                        fluid
                        search
                        selection
                        clearable
                        options={clanOptions}
                        value={joinClan.id}
                        onChange={(event, data) => this.handleJoinClanIdChange(data.value as string)}
                      />
                    </Form.Field>
                    <Form.Field required>
                      <label>Passwort</label>
                      <Input
                        action={
                          <Button
                            icon
                            basic
                            onClick={(e) => {e.preventDefault(); this.handleJoinClanShowPasswordToggle();}}
                          >
                            <Icon name={joinClan.showPassword ? 'eye slash' : 'eye'}/>
                          </Button>
                        }
                        fluid
                        placeholder='Passwort'
                        type={joinClan.showPassword ? 'text' : 'password'}
                        value={joinClan.password}
                        onChange={(event) => this.handleJoinClanPasswordChange(event.target.value)}
                      />
                      {joinClan.errors.password && joinClan.errors.password.length > 0 &&
                        <Label basic color='red' pointing>
                          {joinClan.errors.password.map((x, k) => <p key={k}>{x}</p>)}
                        </Label>
                      }
                    </Form.Field>
                    <Form.Field>
                      <Button primary>
                        <Icon name='sign in'/>
                        Beitreten
                      </Button>
                    </Form.Field>
                  </Form>
                </Grid.Column>
                <Grid.Column>
                  <Header icon>
                    <Icon name='users'/>
                    Erstelle einen Clan
                  </Header>
                  <Form
                    loading={createClan.isLoading}
                    error={createClan.serverError}
                    onSubmit={() => this.handleCreateClan()}
                  >
                    <Form.Field required>
                      <label>Name des Clans</label>
                      <Input
                        type='text'
                        fluid
                        placeholder='Name'
                        value={createClan.name}
                        onChange={(event) => this.handleCreateClanNameChange(event.target.value)}
                      />
                      {createClan.errors.name && createClan.errors.name.length > 0 &&
                      <Label basic color='red' pointing>
                        {createClan.errors.name.map((x, k) => <p key={k}>{x}</p>)}
                      </Label>
                      }
                    </Form.Field>
                    <Form.Field required>
                      <label>Passwort</label>
                      <Input
                        action={
                          <Button
                            icon
                            basic
                            onClick={(e) => {e.preventDefault(); this.handleCreateClanShowPasswordToggle();}}
                          >
                            <Icon name={joinClan.showPassword ? 'eye slash' : 'eye'}/>
                          </Button>
                        }
                        fluid
                        placeholder='Passwort'
                        type={joinClan.showPassword ? 'text' : 'password'}
                        value={createClan.password}
                        onChange={(event) => this.handleCreateClanPasswordChange(event.target.value)}
                      />
                      {createClan.errors.password && createClan.errors.password.length > 0 &&
                      <Label basic color='red' pointing>
                        {createClan.errors.password.map((x, k) => <p key={k}>{x}</p>)}
                      </Label>
                      }
                    </Form.Field>
                    <Form.Field>
                      <Button primary>
                        <Icon name='plus'/>
                        Erstellen
                      </Button>
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </Container>
    );
  }
}