import React, { Component, createRef } from 'react';
import { Form, Grid, Input, Label, Menu, Message, Modal, Checkbox } from 'semantic-ui-react';
import ResetPassword from './ResetPassword';
import ReCAPTCHA from 'react-google-recaptcha';
import Config from '../../Config';
import axios from 'axios';
import {UserInfo} from '../../types';

export interface ILoginProps {
  login: (
    (
      user: UserInfo,
      keepLogin: boolean,
    ) => void
  ),
}

interface ILoginState {
  isOpen: boolean,
  isLoading: boolean,
  email: string,
  password: string,
  keepLogin: boolean,
  serverError: boolean,
  errors: ILoginErrors,
}

interface ILoginErrors {
  email: string[],
  password: string[],
  google_recaptcha_value: string[],
}

interface ILoginResponse {
  id: string,
  username: string,
  token: string,
  payed: boolean,
  in_clan: boolean,
}

const emptyState = {
  isOpen: false,
  isLoading: false,
  email: '',
  password: '',
  keepLogin: true,
  serverError: false,
  errors: {} as ILoginErrors,
} as ILoginState;

export default class Login extends Component<ILoginProps, ILoginState> {
  private recaptchaRef = createRef<ReCAPTCHA>();
  
  constructor(props: ILoginProps) {
    super(props);

    this.state = emptyState;
  }

  handleEmailChange(value: string) {
    this.setState({
      email: value,
    });
  }

  handlePasswordChange(value: string) {
    this.setState({
      password: value,
    });
  }

  handleKeepLoginChange(keepLogin: boolean) {
    this.setState({
      keepLogin: keepLogin,
    });
  }

  handleSubmit() {
    const {email, password, isLoading} = this.state;

    if (isLoading) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    
    axios.post('/api/authenticate', {
      email: email, password: password, google_recaptcha_value: this.recaptchaRef.current ? this.recaptchaRef.current.getValue() : '',
    }).then(response => {
      const data = response.data as ILoginResponse;

      if (data) {
        const {id, username, token, in_clan, payed} = data;
        const {keepLogin} = this.state;

        const user = {
          id: id,
          username: username,
          token: token,
          payed: payed,
          inClan: in_clan,
        } as UserInfo;

        this.props.login(user, keepLogin);
        let newState = emptyState;
        emptyState.isOpen = false;
        this.setState(newState);
      } else {
        this.setState({
          serverError: true,
        })
      }
    }).catch(error => {
      if (error.response.status === 400) {
        const errors = error.response.data.errors as ILoginErrors;

        if (errors) {
          this.setState({
            errors: errors,
          });
        } else {
          this.setState({
            errors: {
              email: ['Die E-Mail Adresse war eventuell nicht korrekt'],
              password: ['Das Passwort war eventuell nicht korrekt.'],
              google_recaptcha_value: [],
            } as ILoginErrors,
          });
        }
      } else {
        this.setState({
          serverError: true,
        });
      }
    }).finally(() => {
      if (this.recaptchaRef.current) {
        this.recaptchaRef.current.reset();
      }

      this.setState({
        isLoading: false,
      });
    });
  }

  close() {
    this.setState({
      isOpen: false,
    });
  }

  open() {
    if (this.recaptchaRef.current) {
      this.recaptchaRef.current.reset();
    }

    let newState = emptyState;
    newState.isOpen = true;
    
    this.setState(emptyState);
  }

  render() {
    const {isOpen, isLoading, email, password, serverError, errors, keepLogin} = this.state;

    const trigger = (<Menu.Item onClick={() => this.open()}>
        Login
      </Menu.Item>);
    
    return (<Modal open={isOpen} trigger={trigger}
                   centered={false} size="mini" onClose={() => this.close()}
      >
        <Modal.Header>
          Einloggen
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form loading={isLoading} error={serverError} onSubmit={() => {
              if (this.recaptchaRef.current) {
                this.recaptchaRef.current.execute();
              }
            }}>
              <Form.Field required>
                <Input type="email" value={email} onChange={(event) => this.handleEmailChange(event.target.value)}
                       placeholder='E-Mail Adresse'/>
                {errors.email && errors.email.length > 0 && <Label basic color='red' pointing>
                  {errors.email.map((x, k) => <p key={k}>{x}</p>)}
                </Label>}
              </Form.Field>
              <Form.Field required>
                <Input
                  type='password' value={password} onChange={(event) => this.handlePasswordChange(event.target.value)}
                  placeholder='Passwort'/>
                {errors.password && errors.password.length > 0 && <Label basic color='red' pointing>
                  {errors.password.map((x, k) => <p key={k}>{x}</p>)}
                </Label>}
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label={<label>Eingeloggt bleiben.</label>}
                  checked={keepLogin}
                  onChange={(event, data) => this.handleKeepLoginChange(data.checked as boolean)}
                />
              </Form.Field>
              <Form.Field>
                <Grid verticalAlign="middle">
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.Button color="orange">
                        Einloggen
                      </Form.Button>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                      <ResetPassword onClose={() => this.close()}/>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Form.Field>
                <ReCAPTCHA ref={this.recaptchaRef} size="invisible" sitekey={Config.RecaptchaKey} badge="inline"
                           onChange={() => this.handleSubmit()}/>
                {errors.google_recaptcha_value && errors.google_recaptcha_value.length > 0 && <Label basic color="red" pointing>
                  {errors.google_recaptcha_value.map((x, k) => <p key={k}>{x}</p>)}
                </Label>}
              </Form.Field>
              <Message error header="Login fehlgeschlagen" content="E-Mail Adresse und/oder Passwort falsch."/>
              <Message error hidden={!serverError} header="Upps, da ist etwas schief gelaufen"
                       content="Bei deiner Anfrage hat etwas nicht funktioniert. Bitte versuche es später noch einmal."/>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}