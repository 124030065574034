import React, {Component} from 'react';
import {Container, Grid} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import Contact from'./Contact';

export default class Footer extends Component {
  render() {
    return (
      <div className='section light'>
        <Container textAlign='center'>
          <Grid stackable columns={3}>
            <Grid.Row>
              <Grid.Column>
                <a href='//www.multimediajugend.de' target='_blank' rel='noopener noreferrer'>
                  <img src='/images/mjslogo.png' alt='Multimediale Jugendarbeit Sachsen e.V.' />
                  www.multimediajugend.de
                </a>
              </Grid.Column>
              <Grid.Column>
                <p>&copy; Multimediale Jugendarbeit Sachsen e.V. 2016-2020</p>
              </Grid.Column>
              <Grid.Column>
                <p>
                  <Link to='/Impressum'>Impressum</Link>
                </p>
                <p>
                  <Link to='/AGB'>AGB</Link>
                </p>
                <p>
                  <Link to='/Datenschutz'>Datenschutz</Link>
                </p>
                <p>
                  <Contact />
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}