import React, { ChangeEvent, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import axios from 'axios';
import { Button, Container, Form, Header, Input, Label, Message } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

interface RouteInfo {
  mail?: string,
  code?: string,
}

interface IVerifyPasswordResetProps extends RouteComponentProps<RouteInfo> {

}

interface IVerificationErrors {
  email: string[],
  verification_code: string[],
  password: string[],
  confirm_password: string[],
}

interface IVerifyPasswordResetState {
  isLoading: boolean,
  email: string,
  password: string,
  confirmPassword: string,
  code: string,
  errors: IVerificationErrors,
  success: boolean,
  serverError: boolean,
}

const emptyState = {
  isLoading: false,
  email: '',
  code: '',
  password: '',
  confirmPassword: '',
  errors: {} as IVerificationErrors,
  success: false,
  serverError: false,
} as IVerifyPasswordResetState;

export default class VerifyPasswordReset extends Component<IVerifyPasswordResetProps, IVerifyPasswordResetState> {
  constructor(props: IVerifyPasswordResetProps) {
    super(props);

    this.state = emptyState;
  }

  postVerification(): void{
    const {email, code, isLoading, password, confirmPassword} = this.state;

    if(isLoading) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    axios.post('/api/authenticate/verify-password', {
      email: email,
      verification_code: code,
      password: password,
      confirm_password: confirmPassword,
    }).then(response=> {
      let newState = emptyState;
      newState.success = true;
      this.setState(newState);
    }).catch(error => {
      if(error.response.status === 400) {
        const errors = error.response.data.errors as IVerificationErrors;
        this.setState({
          errors: errors,
        });
      } else {
        this.setState({
          serverError: true,
        })
      }
    }).finally(() => {
      this.setState({
        isLoading: false
      })
    })
  }

  componentDidMount(): void {
    const {mail, code} = this.props.match.params;

    this.setState({
      email: mail ? mail : '',
      code: code ? code : '',
    });
  }

  handleEmailChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      email: event.target.value,
    })
  }

  handleCodeChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      code: event.target.value,
    })
  }

  handlePasswordChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      password: event.target.value,
    })
  }

  handleConfirmPasswordChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      confirmPassword: event.target.value,
    })
  }

  render() {
    const {email, code, password, confirmPassword, errors, isLoading, success, serverError} = this.state;

    return (
      <Form
        inverted
        loading={isLoading}
        success={success}
        error={serverError}
        onSubmit={() => this.postVerification()}
      >
        <Header as='h1' textAlign='center' inverted>
          Passwort zurücksetzen
        </Header>
        <Container text>
          <Form.Group widths='equal'>
            <Form.Field required>
              <label>E-Mail Adresse</label>
              <Input
                type='email'
                placeholder='max@muster.mail'
                value={email}
                onChange={(event) => this.handleEmailChange(event)}
              />
              {errors.email && errors.email.length > 0 &&
                <Label basic color='red' pointing>
                  {errors.email.map((x, k) => <p key={k}>{x}</p>)}
                </Label>
              }
            </Form.Field>
            <Form.Field required>
              <label>Verifizerungscode</label>
              <Input
                placeholder='Verifizierungscode'
                value={code}
                onChange={(event) => this.handleCodeChange(event)}
              />
              {errors.verification_code && errors.verification_code.length > 0 &&
                <Label basic color='red' pointing>
                  {errors.verification_code.map((x, k) => <p key={k}>{x}</p>)}
                </Label>
              }
            </Form.Field>
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field required>
              <label>Passwort</label>
              <Input
                type='password'
                placeholder='Passwort'
                value={password}
                onChange={(event) => this.handlePasswordChange(event)}
              />
              {errors.password && errors.password.length > 0 &&
                <Label basic color='red' pointing>
                  {errors.password.map((x, k) => <p key={k}>{x}</p>)}
                </Label>
              }
            </Form.Field>
            <Form.Field required>
              <label>Passwort wiederholen</label>
              <Input
                type='password'
                placeholder='Passwort wiederholen'
                value={confirmPassword}
                onChange={(event) => this.handleConfirmPasswordChange(event)}
              />
              {errors.confirm_password && errors.confirm_password.length > 0 &&
                <Label basic color='red' pointing>
                  {errors.confirm_password.map((x, k) => <p key={k}>{x}</p>)}
                </Label>
              }
            </Form.Field>
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field>
              <Button color='orange' size='big' className='verify-button' >
                Bestätigen
              </Button>
            </Form.Field>
            <Form.Field>
              <Link to='/' className='ui big orange inverted button'>
                Zur Hauptseite
              </Link>
            </Form.Field>
          </Form.Group>
        </Container>
        <Container textAlign='center'>
          <Message error>
            <Message.Header>Das Passwort konnte nicht geändert werden.</Message.Header>
            <p>Vermutlich ist dein Bestätigungs-Code abgelaufen.</p>
            <p>Dieser ist nur 24 Stunden gültig. Sollte dies der Fall sein, versuche noch einmal dein Passwort zurückzusetzen.</p>
            <p>Solltest du andere Probleme mit dem Zurücksetzen deines Passworts haben, kannst du uns auch gern kontaktieren.</p>
          </Message>
          <Message success>
            <Message.Header>Verifizierung erfolgreich</Message.Header>
            <Message.Content>
              <p>Dein Passwort wurde erfolgreich geändert. Du kannst dich mit diesem nun einloggen.</p>
            </Message.Content>
          </Message>
        </Container>
      </Form>
    );
  }
}