import React, { ChangeEvent, Component, FormEvent, createRef } from 'react';
import { CheckboxProps, Form, Input, Message, Label, Modal, TextArea, Checkbox } from 'semantic-ui-react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Config from '../../Config';

interface IContactState {
  name: string,
  email: string,
  text: string,
  acceptPrivacy: boolean,
  isLoading: boolean,
  success: boolean,
  errors: IContactErrors,
  serverError: boolean,
}

const emptyState = {
  name: '',
  email: '',
  text : '',
  acceptPrivacy: false,
  isLoading: false,
  success: false,
  errors: {} as IContactErrors,
  serverError: false,
} as IContactState;

interface IContactErrors {
  name: string[],
  email: string[],
  text: string[],
  accept_privacy_statement: string[],
  google_recaptcha_value: string[],
}

function nameof<T>(key: keyof T, instance?: T): keyof T {
  return key;
}

export default class Contact extends Component<any, IContactState> {
  private recaptchaRef = createRef<ReCAPTCHA>();

  constructor(props: any) {
    super(props);
    
    this.state = emptyState;
  }

  handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      name: event.target.value
    });
  }

  handleMailChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      email: event.target.value
    });
  }

  handleTextChange(event: FormEvent<HTMLTextAreaElement>) {
    this.setState({
      text: event.currentTarget.value
    });
  }

  handlePrivacyChange(event: FormEvent, data: CheckboxProps) {
    this.setState({
      acceptPrivacy: data.checked ? data.checked : false
    });
  }

  handleSubmit() {
    const {isLoading, name, email, text, acceptPrivacy} = this.state;

    if (isLoading) {
      return;
    }

    this.setState({
      isLoading: true
    });

    axios.post('/api/contact', {
      name: name,
      email: email,
      text: text,
      accept_privacy_statement: acceptPrivacy,
      google_recaptcha_value: this.recaptchaRef.current
        ? this.recaptchaRef.current.getValue()
        : '',
    })
      .then(response => {
        let newState = emptyState;
        newState.success = true;
        this.setState(newState);
      })
      .catch(error => {
        console.error('error');
        if (error.response.status === 400) {
          const errors = error.response.data.errors as IContactErrors;
          this.setState({
            errors: errors
          });
        } else {
          this.setState({
            serverError: true,
          });
        }
      })
      .finally(() => {
        if(this.recaptchaRef.current) {
          this.recaptchaRef.current.reset();
        }
        
        this.setState({
          isLoading: false
        });
      })
  }

  componentDidMount(): void {
    this.setState(emptyState);
  }
  
  onOpenContact(): void {
    this.setState(emptyState);
    if(this.recaptchaRef.current) {
      this.recaptchaRef.current.reset();
    }
  }

  render() {
    const {name, email, text, isLoading, success, serverError, errors, acceptPrivacy} = this.state;

    return (
      <Modal
        trigger={<a href='Kontakt' onClick={(e) => {e.preventDefault()}}>Kontakt</a>}
        centered={false}
        onOpen={() => this.onOpenContact()}>
        <Modal.Header>Kontaktformular</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              loading={isLoading}
              success={success}
              error={serverError}
              onSubmit={() => {
                if (this.recaptchaRef && this.recaptchaRef.current) {
                  this.recaptchaRef.current.execute();
                }
              }
              }>
              <Form.Group widths='equal'>
                <Form.Field required error={errors.name && errors.name.length > 0}>
                  <label>Dein Name</label>
                  <Input
                    type='text'
                    name={nameof<IContactState>('name')}
                    placeholder='Max Mustermann'
                    value={name}
                    onChange={(event) => this.handleNameChange(event)}
                    required
                  />
                  {errors.name && errors.name.length > 0 &&
                  <Label basic color='red' pointing>
                    {errors.name.map((x, k) => <p key={k}>{x}</p>)}
                  </Label>
                  }
                </Form.Field>
                <Form.Field required error={errors.email && errors.email.length > 0}>
                  <label>E-Mail Adresse</label>
                  <Input
                    type='email'
                    name={nameof<IContactState>('email')}
                    placeholder='max@muster.mail'
                    value={email}
                    onChange={(event) => this.handleMailChange(event)}
                    required
                  />
                  {errors.email && errors.email.length > 0 &&
                  <Label basic color='red' pointing>
                    {errors.email.map((x, k) => <p key={k}>{x}</p>)}
                  </Label>
                  }
                </Form.Field>
              </Form.Group>
              <Form.Field required errors={errors.text && errors.text.length > 0}>
                <label>Betreff</label>
                <TextArea
                  placeholder='Wie können wir dir helfen?'
                  rows={5}
                  required
                  value={text}
                  onChange={(event) => this.handleTextChange(event)}
                />
                {errors.text && errors.text.length > 0 &&
                <Label basic color='red' pointing>
                  {errors.text.map((x, k) => <p key={k}>{x}</p>)}
                </Label>
                }
              </Form.Field>
              <Form.Field required
                          error={errors.accept_privacy_statement && errors.accept_privacy_statement.length > 0}>
                <Checkbox
                  label={<label>Ich akzeptiere die <Link to='/Datenschutz'>Datenschutzerklärung</Link>.</label>}
                  onChange={(event, data) => this.handlePrivacyChange(event, data)}
                  checked={acceptPrivacy}
                /><br/>
                {errors.accept_privacy_statement && errors.accept_privacy_statement.length > 0 &&
                <Label basic color='red' pointing>
                  {errors.accept_privacy_statement.map((x, k) => <p key={k}>{x}</p>)}
                </Label>
                }
              </Form.Field>
              <Form.Group widths='equal'>
                <Form.Field>
                  <ReCAPTCHA
                    ref={this.recaptchaRef}
                    size='invisible'
                    sitekey={Config.RecaptchaKey}
                    onChange={() => this.handleSubmit()}
                    badge='inline'
                  />
                  {errors.google_recaptcha_value && errors.google_recaptcha_value.length > 0 &&
                  <Label basic color='red' pointing>
                    {errors.google_recaptcha_value.map((x, k) => <p key={k}>{x}</p>)}
                  </Label>
                  }
                </Form.Field>
                <Form.Field>
                  <Form.Button className='submit-contact' color='orange'>
                    Abschicken
                  </Form.Button>
                </Form.Field>
              </Form.Group>
              <Message
                success
                header='Anfrage abgeschickt'
                content='Deine Anfrage wurde erfolgreich an das Clanwars-Team geschickt. Bitte beachte, dass wir auch nur ehrenamtlich arbeiten und die Antwort auch einmal ein paar Tage dauern kann.'
              />
              <Message
                error
                header='Upps, da ist etwas schief gelaufen'
                content='Bei deiner Anfrage hat etwas nicht funktioniert. Bitte versuche es später noch einmal.'
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}