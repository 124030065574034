import React, {Component} from 'react';
import {Dimmer, Loader} from 'semantic-ui-react';
import {Route, Switch} from 'react-router';
import axios from 'axios';
import './App.css';

import Config from './Config';
import {UserInfo} from './types';
import Navigation from './components/Layout/Navigation';
import Footer from './components/Layout/Footer';
import Privacy from './components/Layout/Privacy';
import Terms from './components/Layout/Terms';
import Impressum from './components/Layout/Impressum';
import Clan from './components/Clan'

import Landing from './components/Landing';
import Register from './components/Landing/Register';
import { IPaymentOverviewDetails, IPaymentTicket } from './components/Clan/PaymentOverviewModal';
import Success from './components/PayPal/Success';

interface IAppState {
  isLoading: boolean,
  isError: boolean,
  user: UserInfo,
  paymentDetails: IPaymentOverviewDetails,
}

interface IClientConfigurationResponse {
  google_recaptcha_site_key: string,
  user_id: string,
  username: string,
  payed: boolean,
  in_clan: boolean,
  fixed_fee: number,
  flexible_fee: number,
  single_ticket_price: number,
  group_ticket_price: number,
}

class App extends Component<any, IAppState> {
  private login(user: UserInfo, keepLogin: boolean): void {
    if(keepLogin) {
      localStorage.setItem('token', user.token);
    } else {
      sessionStorage.setItem('token', user.token);
      localStorage.clear();
    }

    App.setAxiosToken(user.token);
    const {paymentDetails} = this.state;
    
    if(!user.payed) {
      this.addTicket(user.id, user.username);
    }

    this.setState(prev => ({
      user: user,
      paymentDetails: {
        ...prev.paymentDetails,
        userid: user.id,
      },
    }));
  }
  
  private setUserClanState(inClan: boolean): void {
    this.setState(prev => ({
      user: {
        ...prev.user,
        inClan: inClan,
      },
      paymentDetails: {
        ...prev.paymentDetails,
        userIsInClan: inClan,
      }
    }));
  }

  private logout(): void {
    localStorage.clear();
    sessionStorage.clear();

    this.setState(prev => ({
      user: {} as UserInfo,
      paymentDetails: {
        ...prev.paymentDetails,
        userid: '',
        tickets: [],
      },
    }));

    // TODO: send logout to API
    App.resetAxiosToken();
  }

  private static setAxiosToken(token: string): void {
    axios.defaults.headers.common = {
      'Authorization': `bearer ${token}`,
    };
  }

  private static resetAxiosToken() {
    delete axios.defaults.headers.common['Authorization'];
  }
  
  private removeTicket(id: string) : void {
    const {paymentDetails} = this.state;
    const {tickets} = paymentDetails;
    
    // Remove the ticket with the given ID from the ticket-list
    let newTickets = tickets.filter((ticket) => {
      return ticket.id !== id
    });

    this.setState(prev => ({
      paymentDetails: {
        ...prev.paymentDetails,
        tickets: newTickets,
      },
    }));
  }
  
  private addTicket(id: string, username: string) : void {
    const {paymentDetails} = this.state;
    const {tickets} = paymentDetails;
    
    if(id.length === 0 || username.length === 0) {
      return;
    }
    
    if(tickets.find(t => t.id === id)) {
      return;
    }
    
    // Remove the ticket with the given ID from the ticket-list
    tickets.push({
      id: id,
      username: username,
    });

    this.setState(prev => ({
      paymentDetails: {
        ...prev.paymentDetails,
        tickets: tickets,
      },
    }));
  }

  constructor(props: any) {
    super(props);

    const token = localStorage.getItem('token') ? localStorage.getItem('token') : sessionStorage.getItem('token');

    if(token) {
      App.setAxiosToken(token);
    }

    this.state = {
      isLoading: true,
      isError: false,
      user: {} as UserInfo,
      paymentDetails: {} as IPaymentOverviewDetails,
    };

    axios.get('/api/ClientConfiguration')
      .then(response => {
        const data = response.data as IClientConfigurationResponse;

        Config.RecaptchaKey = data.google_recaptcha_site_key;
        // get username and id from API, based on the token
        if(data) {
          const payment = {
            userid: data.user_id,
            userIsInClan: data.in_clan,
            fixedFee: data.fixed_fee,
            flexibleFee: data.flexible_fee,
            removeTicket: (id: string) => this.removeTicket(id),
            addTicket: (id: string, username: string) => this.addTicket(id, username),
            singleTicketPrice: data.single_ticket_price,
            groupTicketPrice: data.group_ticket_price,
            tickets: [] as IPaymentTicket[],
          } as IPaymentOverviewDetails;
          let user = {} as UserInfo; 
          if (data.username && data.username.length > 0) {
            user = {
              id: data.user_id,
              username: data.username,
              token: '',
              payed: data.payed,
              inClan: data.in_clan,
            } as UserInfo;

            if(!user.payed) {
              payment.tickets.push({
                id: user.id,
                username: user.username,
              } as IPaymentTicket);
            }
          }

          this.setState({
            user: user,
            paymentDetails: payment,
          });
        }else {
          this.setState({
            isError: true,
          });
          return;
        }
      })
      .catch(error => {
        this.setState({
          isError: true,
        });
      }).finally(() => {
      this.setState({
        isLoading: false,
      });
    });
  }

  private renderLayout() {
    const {user, paymentDetails} = this.state;
    
    return (
      <div>
        <Navigation
          login={(user, keepLogin) => this.login(user, keepLogin)}
          logout={() => this.logout()}
          user={user}
          paymentDetails={paymentDetails}
        />
        <Route path='/Datenschutz' component={Privacy} />
        <Route path='/AGB' component={Terms} />
        <Route path='/verifizieren' component={Register} />
        <Route path='/passwort' component={Register} />
        <Route path='/Impressum' component={Impressum} />
        {user.id && <Route path='/paypal/success' component={Success}/>}
        {user.id &&
          <Route
            exact
            path='/'
            render={() => <Clan setUserClanState={(inClan) => this.setUserClanState(inClan)} userInfo={user} paymentDetails={paymentDetails}/>}
          />
        }
        {!user.id && <Route exact path='/' component={Landing} />}
        <Footer/>
      </div>
    );
  }

  render() {
    const {isLoading, user} = this.state;

    return (
      <div className="App">
        {
          isLoading 
            ? <Dimmer active><Loader size='massive'>Lade Seite</Loader></Dimmer>
            : this.renderLayout()
        }
      </div>
    );
  }
}

export default App;