import React, {Component} from 'react';
import {Container, Grid, Header} from 'semantic-ui-react';

export default class Impressum extends Component {
  render() {
    return (
      <div className='impressum section' id='Impressum'>
        <Container>
          <Header as='h1'>Impressum</Header>
          <Grid divided>
            <Grid.Row>
              <Grid.Column width={10}>
                <Header as='h3'>Verantwortlich für den Inhalt nach §55 Abs.2 RStV.</Header>
                <p>
                  Multimediale Jugendarbeit Sachsen e.V.<br/>
                  Bahnhofstraße 1<br/>
                  09669 Frankenberg
                </p>
              </Grid.Column>
              <Grid.Column width={6}>
                <Header as='h3'>Angaben gemäß §5 TMG</Header>
                <p>
                  Multimediale Jugendarbeit Sachsen e.V.<br/>
                  Bahnhofstraße 1<br/>
                  09669 Frankenberg
                </p>
                <p>
                  E-Mail:&nbsp;
                  <a
                    href="mailto:&#105;&#110;&#102;&#111;&#064;&#104;&#115;&#102;&#045;&#099;&#108;&#097;&#110;&#119;&#097;&#114;&#115;&#046;&#100;&#101;">
                    &#105;&#110;&#102;&#111;&#064;&#104;&#115;&#102;&#045;&#099;&#108;&#097;&#110;&#119;&#097;&#114;&#115;&#046;&#100;&#101;
                  </a>
                </p>
                <p>
                  Amtsgericht: Chemnitz
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Header as='h2'>Haftungsausschluss</Header>
          <Header as='h4'>1. Inhalt des Onlineangebotes</Header>
          <div>
            <p>
              Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der
              bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller
              oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw.
              durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich
              ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges
              Verschulden vorliegt.
            </p>
            <p>
              Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der
              Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
              Veröffentlichung zeitweise oder endgültig einzustellen.
            </p>
          </div>
          <Header as='h4'>2. Verweise und Links</Header>
          <div>
            <p>
              Bei direkten oder indirekten Verweisen auf fremde Internetseiten ("Links"), die außerhalb des
              Verantwortungsbereiches des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in
              Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar
              wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.
            </p>
            <p>
              Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf
              den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder
              die Urheberschaft der gelinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert
              er sich hiermit ausdrücklich von allen Inhalten aller gelinkten /verknüpften Seiten, die nach der
              Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes
              gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern,
              Diskussionsforen und Mailinglisten.
            </p>
            <p>
              Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung
              oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite,
              auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich
              verweist.
            </p>
          </div>
          <Header as='h4'>3. Urheber- und Kennzeichenrecht</Header>
          <div>
            <p>
              Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente,
              Videosequenzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videosequenzen und
              Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte
              zurückzugreifen.<br/>
              Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen
              unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten
              der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu
              ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!
            </p>
            <p>
              Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der
              Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in
              anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht
              gestattet.
            </p>
          </div>
          <Header as='h4'>4. Datenschutz</Header>
          <div>
            <p>
              Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten
              (Emailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf
              ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist - soweit
              technisch möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten
              oder eines Pseudonyms gestattet.
            </p>
          </div>
          <Header as='h4'>5. Rechtswirksamkeit dieses Haftungsausschlusses</Header>
          <div>
            <p>
              Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite
              verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht,
              nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem
              Inhalt und ihrer Gültigkeit davon unberührt.
            </p>
          </div>
        </Container>
      </div>
    )
  }
}