import React, { Component, ReactElement } from 'react';
import {Button, Modal, Input, Label, Message, Form} from 'semantic-ui-react';
import axios from 'axios';

interface IChangePasswordState {
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string,
  isLoading: boolean,
  success: boolean,
  serverError: boolean,
  errors: IChangePasswordErrors,
  open: boolean,
}

export interface IChangePasswordProps {
  trigger: ReactElement,
}

interface IChangePasswordErrors {
  old_password: string[],
  new_password: string[],
  confirm_new_password: string[],
}

const emptyState = {
  open: false,
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  isLoading: false,
  success: false,
  serverError: false,
  errors: {} as IChangePasswordErrors,
} as IChangePasswordState;

export default class ChangePassword extends Component<IChangePasswordProps, IChangePasswordState> {
  constructor(props: any) {
    super(props);
    
    this.state = emptyState;
  }

  private handleOldPasswordChange(password: string): void {
    this.setState({
      oldPassword: password
    })
  }

  private handleNewPasswordChange(password: string): void {
    this.setState({
      newPassword: password
    })
  }

  private handleConfirmNewPasswordChange(password: string): void {
    this.setState({
      confirmNewPassword: password
    })
  }
  
  private handleSubmit(): void {
    const {isLoading, oldPassword, newPassword, confirmNewPassword} = this.state;
    
    if(isLoading) {
      return;
    }
    
    this.setState({
      isLoading: true,
    });
    
    axios.patch('/api/authenticate/change-password', {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_new_password: confirmNewPassword
    }).then(() => {
      let newState = emptyState;
      newState.success = true;
      newState.open = true;
      this.setState(newState);
    }).catch(error => {
      if(error.response.status === 400) {
        const errors = error.response.data.errors as IChangePasswordErrors;
        if(errors) {
          this.setState({
            errors: errors
          });
        }
      } else {
        this.setState({
          serverError: true,
        });
      }
    }).finally(() => {
      this.setState({
        isLoading: false,
      })
    })
  }
  
  private handleOpen(): void {
    let openState = emptyState;
    openState.open = true;    
    this.setState(openState);
  }
  
  private handleClose(): void {
    this.setState({
      open: false,
    })
  }

  render() {
    const {oldPassword, newPassword, confirmNewPassword, open, isLoading, success, serverError, errors} = this.state;
    const {trigger} = this.props;
    
    return (
      <Modal
        trigger={trigger}
        centered={false}
        closeIcon
        closeOnDimmerClick={false}
        size='tiny'
        open={open}
        onOpen={() => this.handleOpen()}
        onClose={() => this.handleClose()}
      >
        <Modal.Header>Passwort ändern</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              loading={isLoading}
              success={success}
              error={serverError}
              onSubmit={() => this.handleSubmit()}
            >
              <Form.Field
                required
                error={errors.old_password && errors.old_password.length > 0}
              >
                <label>Altes Passwort</label>
                <Input
                  type='password'
                  placeholder='Altes Passwort'
                  value={oldPassword}
                  onChange={(event) => this.handleOldPasswordChange(event.target.value)}
                />
                {errors.old_password && errors.old_password.length > 0 &&
                  <Label basic color='red' pointing>
                    {errors.old_password.map((x, k) => <p key={k}>{x}</p>)}
                  </Label>
                }
              </Form.Field>
              <Form.Field
                required
                error={errors.new_password && errors.new_password.length > 0}
              >
                <label>Neues Passwort</label>
                <Input
                  type='password'
                  placeholder='Neues Passwort'
                  value={newPassword}
                  onChange={(event) => this.handleNewPasswordChange(event.target.value)}
                />
                {errors.new_password && errors.new_password.length > 0 &&
                <Label basic color='red' pointing>
                  {errors.new_password.map((x, k) => <p key={k}>{x}</p>)}
                </Label>
                }
              </Form.Field>
              <Form.Field
                required
                error={errors.confirm_new_password && errors.confirm_new_password.length > 0}
              >
                <label>Neues Passwort wiederholen</label>
                <Input
                  type='password'
                  placeholder='Neues Passwort wiederholen'
                  value={confirmNewPassword}
                  onChange={(event) => this.handleConfirmNewPasswordChange(event.target.value)}
                />
                {errors.confirm_new_password && errors.confirm_new_password.length > 0 &&
                <Label basic color='red' pointing>
                  {errors.confirm_new_password.map((x, k) => <p key={k}>{x}</p>)}
                </Label>
                }
              </Form.Field>
              <Form.Group>
                <Form.Field>
                  <Form.Button className='submit-contact' color='orange'>
                    Speichern
                  </Form.Button>
                </Form.Field>
                <Form.Field>
                  <Button
                    className='submit-contact'
                    color='orange'
                    basic
                    onClick={(e) => {e.preventDefault(); this.handleClose();}}
                  >
                    Abbrechen
                  </Button>
                </Form.Field>
              </Form.Group>
              <Message
                success
                header='Das Passwort wurde geändert'
              />
              <Message
                error
                header='Upps, da ist etwas schief gelaufen'
                content='Es ist escheinbar ein Problem mit dem Server aufgetreten. Bitte versuche es später noch einmal.'
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
    
  }
}